import React from 'react';
import { Link as TLink } from 'theme-ui';
import { Link } from 'gatsby';
import useMinimalBlogConfig from '../hooks/use-minimal-blog-config';
import replaceSlashes from '../utils/replaceSlashes';

type TagsProps = {
  tags: {
    name: string;
    slug: string;
  }[];
};

const ItemTags = ({ tags }: TagsProps) => {
  const { tagsPath, basePath } = useMinimalBlogConfig();

  return (
    <React.Fragment>
      {tags.map((tag, index) => (
        <React.Fragment key={tag.slug}>
          {!!index && `, `}
          <TLink
            sx={{ variant: 'links.simple' }}
            as={Link}
            to={replaceSlashes(`/${basePath}/${tagsPath}/${tag.slug}`)}
          >
            {tag.name}
          </TLink>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default ItemTags;
